import { Box } from "@mui/system";
import React, { /*useContext*/ useState } from "react";

import { Auth } from "aws-amplify";

import { awsSignIn } from "../../redux/auth/authSlice";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { CircularProgress, Typography } from "@mui/material";
import { CustomButton } from "../CustomButton/CustomButton";

import { useNavigate } from "react-router-dom";
import CustomInput from "../CustomInput/CustomInput";
import "react-phone-number-input/style.css";

import { useForm } from "../../Hooks/useForm";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
// import ReactCodeInput from "react-verification-code-input";
import { Theme } from "../../config/aws-exports";
import { useDispatch } from "react-redux";
import validate from "./SignInSignUpValidationRules/SignInValidationRules";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";
import { HeadingPrimary } from "../../styles/Heading";
import Swal from "sweetalert2";
import validateResetPassword from "./SignInSignUpValidationRules/ResetPasswordValidation";
export const inputBoxStyle = {
  width: { xs: 300, sm: 450 },
};
export const displayCenter = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
};

export const inputIconStyle = {
  color: Theme.color.primary,
};

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    values,
    handleChange,
    setValues,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useForm({ showPassword: false });

  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetPasswordOpenFirst, setResetPasswordOpenFirst] = useState(false);
  const [signInForm, setSignInForm] = useState(true);
  const [emailVerification, setEmailVerification] = useState(false);

  // const [verificationCode, setVerificationCode] = useState("");

  const [errors, setErrors] = useState({});

  const [btnLoading, setBtnLoading] = useState(false);
  const [emailVerifyLoader, setEmailVerifyLoader] = useState(false);
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Object.keys(validate(values)).length !== 0) {
      setErrors(validate(values));
      return;
    } else {
      setBtnLoading(true);
      try {
        const user = await Auth.signIn(values.username, values.password);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setSignInForm(false);
          setResetPasswordOpenFirst(true);
          // setSignUp(false);
          setValues({ ...values, showPassword: false });
          setForgotPassword(false);
          setBtnLoading(false);
        }
        const info = await Auth.currentUserInfo();

        let userData = info.attributes;
        userData.eid = info.attributes.sub;
        userData.username = info.username;
        userData.id = info.id;
        userData.tenant = userData.locale.toUpperCase();
        setBtnLoading(false);

        dispatch(awsSignIn(userData));
        navigate("/dashboard");
      } catch (error) {
        console.error(error.code);
        setBtnLoading(false);
        setErrors(cognitoValidation(error));
      }
    }
  };
  const resetPasswordFC = async (event) => {
    event.preventDefault();
    if (Object.keys(validate(values)).length !== 0) {
      setErrors(validateResetPassword(values));
      return;
    } else {
      setResetPasswordLoader(true);
      await Auth.signIn(values.username, values.password).then((users) => {
        if (users.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(users, values.confirmPassword);
        }
      });
      setResetPasswordLoader(false);
      Swal.fire({
        title: "Success",
        text: "Password Changed Successfully",
        icon: "success",
        dangerMode: false,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
      setSignInForm(true);
      setResetPasswordOpenFirst(false);
    }
  };

  const passwordResetFC = async (event) => {
    event.preventDefault();
    if (Object.keys(validate(values)).length !== 0) {
      setErrors(validateResetPassword(values));
      return;
    } else {
      setResetPasswordLoader(true);
      try {
        await Auth.forgotPasswordSubmit(
          values.verificationEmail,
          values.verificationCode,
          values.confirmPassword
        );
        setResetPasswordLoader(false);

        alert("Changed password Successfully");
      } catch (error) {
        setResetPasswordLoader(false);
        setErrors(cognitoValidation(error));
      }
    }
  };

  return (
    <React.Fragment>
      {signInForm && (
        <React.Fragment>
          <Box sx={{ textAlign: "center" }}>
            <HeadingPrimary>Login to your account</HeadingPrimary>
          </Box>
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={displayCenter}
          >
            <Box
              component="div"
              sx={inputBoxStyle}
              style={{ marginTop: "2rem" }}
            >
              <CustomInput
                id="input-username"
                placeholder="Username"
                type="email"
                value={values.username || ""}
                error={errors.email}
                onChange={handleChange("username")}
              >
                <PersonOutlineOutlinedIcon sx={inputIconStyle} />
              </CustomInput>
            </Box>

            <Box component="div" sx={inputBoxStyle}>
              <CustomInput
                id="input-Password"
                placeholder="Password"
                type="password"
                inputValues={values}
                value={values.password || ""}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                onChange={handleChange("password")}
                error={errors.password}
              >
                <LockOutlinedIcon sx={inputIconStyle} />
              </CustomInput>
              <Typography
                onClick={() => {
                  setForgotPassword(true);
                  setSignInForm(false);
                }}
                variant="caption"
                display="block"
                gutterBottom
                sx={{
                  fontSize: "1rem",
                  textAlign: "right",
                  cursor: "pointer",
                }}
              >
                Forgot password?
              </Typography>
            </Box>

            <Box component="div" sx={{ textAlign: "center" }}>
              {btnLoading ? (
                <CustomButton disabled loading="true">
                  <CircularProgress color="inherit" size={20} />
                </CustomButton>
              ) : (
                <CustomButton type="submit" primary>
                  Login
                </CustomButton>
              )}
            </Box>
          </form>
        </React.Fragment>
      )}

      {forgotPassword && !resetPasswordOpenFirst && (
        <ForgotPassword
          emailVerifyLoader={emailVerifyLoader}
          setEmailVerifyLoader={setEmailVerifyLoader}
          setEmailVerification={setEmailVerification}
          passwordResetFC={passwordResetFC}
          setForgotPassword={setForgotPassword}
          setSignInForm={setSignInForm}
          setResetPasswordOpenFirst={setResetPasswordOpenFirst}
          values={values}
          handleChange={handleChange}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          setErrors={setErrors}
          errors={errors}
        />
      )}

      {resetPasswordOpenFirst && (
        <ResetPassword
          resetPasswordFC={resetPasswordFC}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          setErrors={setErrors}
          errors={errors}
        />
      )}
    </React.Fragment>
  );
};

export default SignIn;
