import { Breadcrumbs } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HeadingPrimary } from "../../../styles/Heading";
import PostAndEditJobForm from "../PostAndEditJobForm";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function JobEdit() {
  const { state } = useLocation();
  //  const handleClick = () => {}
  const breadcrumbs = [
    <Link to={state?.dashboard ? "/dashboard" : "/jobs"}>Posted jobs</Link>,
    <Link
      to={state?.dashboard ? `/dashboard/${state?.jid}` : `/jobs/${state?.jid}`}
      state={state}
      // onClick={handleClick}
    >
      View job
    </Link>,
    <p>Edit job</p>,
  ];
  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <HeadingPrimary>Edit job</HeadingPrimary>
      <PostAndEditJobForm />
    </>
  );
}
