import * as React from "react";
import { useDispatch } from "react-redux";
import { awsSignOut } from "../../redux/auth/authSlice";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
// import styled from "styled-components";
import {
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import Tippy from "@tippyjs/react";

export default function DashboardAppBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1 }} />

      <Tippy
        content={
          <Box
            sx={{
              width: "100%",
              minWidth: 100,
              bgcolor: "background.paper",
            }}
          >
            <nav>
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    dense
                    onClick={async () => {
                      try {
                        await Auth.signOut();
                        dispatch(awsSignOut());
                        navigate("/");
                      } catch (error) {
                        navigate("/");
                        console.log("error signing out: ", error);
                      }
                    }}
                  >
                    <ListItemText primary="Sign out" />
                  </ListItemButton>
                </ListItem>
              </List>
            </nav>
          </Box>
        }
        theme="light"
        interactive={true}
        inertia={true}
        arrow
        duration={[350, 200]}
        placement="bottom"
      >
        <Avatar alt="" sx={{ height: 35, width: 35 }} />
      </Tippy>
    </Box>
  );
}
