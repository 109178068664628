import { Avatar, Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { API } from "aws-amplify";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Theme } from "../../../config/aws-exports";
import { HeadingPrimary, HeadingSecondary } from "../../../styles/Heading";
import config from "../../../config/aws-exports";

export default function JobApplication() {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { data } = useQuery(["applications", state?.jid], () =>
    API.post(config.aws_cloud_logic_custom_name, "/viewAppliedSavedJob", {
      body: {
        jid: state?.jid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    }).then((res) => {
      console.log("state==", state);
      return res;
    })
  );

  console.log(data);

  return (
    <>
      {!pathname.includes("profile") &&
        (pathname.includes("application") ||
          pathname === "/jobs/application" ||
          pathname === "/dashboard/application") && (
          <>
            <HeadingPrimary>Job Application</HeadingPrimary>
            <Box
              sx={{
                p: 3,
                border: `1px solid ${Theme.color.grey_light_2}`,
                borderRadius: 1.5,
              }}
            >
              <HeadingSecondary>{state?.title}</HeadingSecondary>

              {data?.body?.map((application, id) => (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ boxShadow: Theme.boxShadow, p: 3, mt: 2 }}
                  key={id}
                >
                  <Avatar sx={{ height: 90, width: 90 }} alt="name" />
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <HeadingSecondary>
                        {application.std_name}
                      </HeadingSecondary>
                      <p>{application.colname}</p>
                    </Box>
                    <p>{application.lname}</p>
                    <p>{application.head_line}</p>
                  </Box>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <p>Grade :</p>
                      <HeadingSecondary>
                        {application.grade}/10
                      </HeadingSecondary>
                    </Box>
                    <Button
                      variant="outlined"
                      color="info"
                      sx={{ textTransform: "unset" }}
                      onClick={() =>
                        navigate("profile", { state: application })
                      }
                    >
                      View profile
                    </Button>
                  </Stack>
                </Stack>
              ))}
            </Box>
          </>
        )}
      <Outlet />
    </>
  );
}
