import styled from "styled-components";
export const LayoutContainer = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  .pro-sidebar > .pro-sidebar-inner {
    background: ${({ theme }) => theme.color.secondary};
    .pro-icon span {
      width: 4rem;
      height: 4rem;
    }
    .pro-icon span svg {
      width: 4rem;
      height: 4rem;
    }
  }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background: ${({ theme }) => theme.color.white};
  }

  .pro-sidebar .pro-menu .pro-menu-item {
    font-size: 1.2rem;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    height: 3.5rem;
  }
  .side-nav {
    font-size: 1.75rem;
    list-style: none;
    padding: 0;

    &__item {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    &__item::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 3px;
      background-color: var(--color-grey-light-2);
      transform: scaleY(0);
      transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
        background-color 0.1s;
    }
    &__item--active {
      span {
        color: ${({ theme }) => theme.color.primary} !important;
        font-weight: bold;
      }
      .side-nav__icon {
        fill: ${({ theme }) => theme.color.primary} !important;
      }
      background-color: whitesmoke;
    }

    &__item--active::before {
      transform: scaleY(1);
      width: 100%;
    }

    &__item:active::before {
      background-color: var(--color-grey-light-2);
    }

    &__link:link,
    &__link:visited {
      color: var(--color-grey-light-1);
      text-decoration: none;
      // text-transform: uppercase;
      display: block;
      padding: 1.5rem 3rem;
      position: relative;
      z-index: 10;

      display: flex;
      align-items: center;
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: white !important;
    }
  }

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: ${({ theme }) => theme.color.secondary};
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
`;
