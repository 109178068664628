import {
  Avatar,
  Backdrop,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  ListItem,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { Theme } from "../../config/aws-exports";
import config from "../../config/aws-exports";
import { HeadingPrimary, HeadingSecondary } from "../../styles/Heading";
import {
  useNavigate,
  useLocation,
  Link,
  Outlet,
  useMatch,
} from "react-router-dom";
import { secondaryButtonStyles } from "../Dashboard/Dashboard";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useQuery } from "react-query";
import { API } from "aws-amplify";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { PostAJobButton } from "../Jobs/Jobs";
import Swal from "sweetalert2";
import "./EmailPopUp.styles.scss";
import EmailPopUp from "./EmailPopUp";

export const primaryButtonStyles = {
  color: Theme.color.primary,
  textTransform: "unset",
  borderColor: Theme.color.primary,
  ":hover": {
    color: Theme.color.primary,
    textTransform: "unset",
    borderColor: Theme.color.primary,
  },
};
export default function Search() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector(authData);
  const [emailIds, setEmailIds] = useState([]);
  const location = useLocation();
  const match = useMatch("/explore/search");
  const [searchDisplay, setSearchDisplay] = useState([]);
  const [search, setSearch] = useState();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const { pathname, state } = location;
  console.log("res search==", JSON.stringify(userDetails.searchBody));
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  useEffect(() => {
    setSearchDisplay(userDetails.srcDisplay);
  }, []);

  const handleChange = (e) => {
    const { id, checked } = e.target;
    console.log(JSON.stringify({ id, checked }, null, 2));
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(search.map((item) => item.std_id.toString()));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const { data, isLoading, isFetching, refetch } = useQuery("search", () =>
    API.post(config.aws_cloud_logic_custom_name, "/getSearchResults", {
      body: {
        ids: userDetails.searchBody,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    }).then((res) => {
      console.log("res==", res);
      setSearch(res.body.search);
      return res.body;
    })
  );
  const handleDelete = (val) => () => {
    console.log("check==", searchDisplay, val);
    var copysrhdis = Object.assign({}, searchDisplay);
    delete copysrhdis[val];

    setSearchDisplay(copysrhdis);
    let sdata = { ...userDetails };

    if (Object.keys(copysrhdis).length > 0) {
      sdata.searchBody = {};
      sdata.searchBody.action = 0;
      sdata.searchBody.eid = userDetails.eid;
      for (let index = 0; index < Object.keys(copysrhdis).length; index++) {
        const element = Object.keys(copysrhdis)[index];
        sdata.searchBody[element] = copysrhdis[element][element];
      }
      sdata.srcDisplay = copysrhdis;
      console.log("sdatasearchBody===", sdata.searchBody);
      sdata.srcDisplay = copysrhdis;
      dispatch(awsSignIn(sdata));
      refetch();
    } else {
      navigate(sdata.prepage);
    }
  };

  return (
    <>
      {match !== null && (
        <>
          <Stack direction="row" alignItems="center">
            <HeadingPrimary>Search results</HeadingPrimary>
            <Box sx={{ flexGrow: 1 }} />

            {/* <PostAJobButton variant="contained" size="small">
              Add filter
            </PostAJobButton> */}
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ ml: 2 }}
            >
              <p>Sort by: </p>
              <Box sx={{ width: 200 }}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    size="small"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <PostAJobButton
              variant="contained"
              size="small"
              disabled={isCheck.length === 0 ? true : false}
              sx={{ ml: 2 }}
              onClick={() => {
                const emails = [];
                data?.search.forEach((email) => {
                  if (isCheck.length !== 0) {
                    isCheck.forEach((check) => {
                      if (check.toString() === email.std_id.toString()) {
                        emails.push({
                          EMAILID: email.emailid,
                          UNAME: email.std_name,
                        });
                      }
                    });
                  }
                });
                setEmailIds(emails);
                handleOpen();
              }}
            >
              Send emails
            </PostAJobButton>
          </Stack>
          <Stack
            sx={{ mt: 3 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <p>Filter By:</p>
              <Box
                sx={{
                  overflowY: "hidden",
                  overflowX: "auto",
                  maxHeight: "65px",
                  display: "flex",
                  flexWrap: "nowrap",
                  maxWidth: "calc(100vw - 540px)",
                  gap: 1,
                }}
              >
                {searchDisplay !== undefined &&
                  Object.keys(searchDisplay).length > 0 &&
                  Object.keys(searchDisplay).map((val, index) => (
                    <Chip
                      key={index}
                      label={searchDisplay[val].name}
                      onDelete={handleDelete(val)}
                    />
                  ))}
              </Box>
            </Stack>

            <div
              style={{
                background: isCheckAll ? `${Theme.color.primary}` : "",
                padding: "3px 16px 3px 9px",
                borderRadius: "4px",
                border: isCheckAll
                  ? `2px solid ${Theme.color.primary}`
                  : `2px solid ${Theme.color.primary}`,
                color: isCheckAll ? Theme.color.white : Theme.color.primary,
                fontWeight: "600",
                maxWidth: "140px",
              }}
            >
              <Checkbox
                sx={{
                  color: isCheckAll ? Theme.color.white : Theme.color.primary,
                  p: 0,
                  "&.Mui-checked": {
                    color: isCheckAll ? Theme.color.white : Theme.color.primary,
                  },
                  mr: 1,
                }}
                name="selectAll"
                id="selectAll"
                onChange={handleSelectAll}
                checked={isCheckAll}
              />
              Select All
            </div>
          </Stack>

          {!isLoading &&
            !isFetching &&
            search?.map((application, id) => {
              const checked = isCheck.includes(application.std_id.toString());
              return (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ boxShadow: Theme.boxShadow, p: 3, mt: 2 }}
                >
                  <Avatar sx={{ height: 90, width: 90 }} alt="name" />
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <HeadingSecondary>
                        {application.std_name}
                      </HeadingSecondary>
                      <FiberManualRecordIcon sx={{ height: 12, width: 12 }} />
                      <p>{application.colname}</p>
                    </Box>
                    <p>Bangalore, India</p>
                    <p>Headline -{application.head_line}</p>
                  </Box>
                  <Stack direction="column" spacing={1} alignItems="center">
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <p>Grade :</p>
                      <HeadingSecondary>
                        {application.grade}/10
                      </HeadingSecondary>
                    </Box>
                    <div
                      style={{
                        background: checked ? `${Theme.color.primary}` : "",
                        padding: "3px 16px 3px 9px",
                        borderRadius: "4px",
                        border: checked
                          ? `2px solid ${Theme.color.primary}`
                          : `2px solid ${Theme.color.primary}`,
                        color: checked
                          ? Theme.color.white
                          : Theme.color.primary,
                        fontWeight: "600",
                        maxWidth: "140px",
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: checked
                            ? Theme.color.white
                            : Theme.color.primary,
                          p: 0,
                          "&.Mui-checked": {
                            color: checked
                              ? Theme.color.white
                              : Theme.color.primary,
                          },
                          mr: 1,
                        }}
                        id={application.std_id.toString()}
                        checked={checked}
                        onChange={handleChange}
                      />
                      {checked ? "Select All" : "Select"}
                    </div>
                    <Button
                      variant="outlined"
                      color="info"
                      size="small"
                      sx={secondaryButtonStyles}
                      onClick={() =>
                        navigate("profile", { state: application })
                      }
                    >
                      View profile
                    </Button>
                  </Stack>
                </Stack>
              );
            })}
          {!isLoading && !isFetching && data?.search.length === 0 && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "80vh" }}
            >
              <h2>Results Not found!</h2>
            </Stack>
          )}
          {/* <pre>{JSON.stringify(isCheck, null, 2)}</pre> */}
          {open && (
            <EmailPopUp open={open} setOpen={setOpen} usersList={emailIds} />
          )}
          <Backdrop open={isLoading} sx={{ zIndex: 1100 }}>
            <CircularProgress />
          </Backdrop>
        </>
      )}

      <Outlet />
    </>
  );
}
