export default function cognitoValidation(error) {
  let errors = {};

  if (error.code === "UserNotFoundException") {
    errors.email = "User does not exist";
  } else if (error.code === "UserNotConfirmedException") {
    errors.email = "User Not Confirmed";
  }
  if (error.code === "NotAuthorizedException") {
    errors.password = "Invalid Password";
  } else if (error.code === "InvalidPasswordException") {
    errors.password = "Please match the password type.";
  }
  if (error.code === "InvalidParameterException") {
    errors.code = "Invalid code entered";
  } else if (error.code === "CodeMismatchException") {
    errors.code = "Code mismatch";
  }

  return errors;
}
