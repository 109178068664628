import { Breadcrumbs } from "@mui/material";
import { Box } from "@mui/system";

import { Theme } from "../../../config/aws-exports";
import { HeadingPrimary } from "../../../styles/Heading";
import PostAndEditJobForm from "../PostAndEditJobForm";

//rect-router-dom
import { Link } from "react-router-dom";

//icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import styled from "styled-components";

const NewJobDiv = styled.div`
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.secondary};
    &:hover {
      text-decoration: underline;
    }
  }
`;
export default function NewJob() {
  const breadcrumbs = [<Link to="/jobs">Posted jobs</Link>, <p>Post a job</p>];

  return (
    <NewJobDiv>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <HeadingPrimary>Post a job</HeadingPrimary>

      <Box
        sx={{
          p: 3,
          border: `1px solid ${Theme.color.grey_light_2}`,
          borderRadius: 1.5,
        }}
      >
        <PostAndEditJobForm />
      </Box>
    </NewJobDiv>
  );
}
