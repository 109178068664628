import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { Theme } from "../../config/aws-exports";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/img/careerED white logo.png";
import { Logo } from "../AppHeader/AppBar.landingPage";

import DashboardIcon from "@mui/icons-material/Dashboard";
import WorkIcon from "@mui/icons-material/Work";
import ExploreIcon from "@mui/icons-material/Explore";
const Aside = ({ image, collapsed, toggled, handleToggleSidebar }) => {
  const { pathname } = useLocation();

  return (
    <ProSidebar
      image={image ? "" : false}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
      style={{
        color: Theme.color.white,
      }}
      className="side-nav"
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <Logo src={logo} size={collapsed ? 2 : 3} 
          style={{objectFit:"contain"}} />
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu>
          <MenuItem
            className={`${
              pathname.indexOf("dashboard") !== -1
                ? "side-nav__item--active"
                : ""
            }`}
            icon={<DashboardIcon className="side-nav__icon" />}
          >
            Dashboard
            <Link to="/dashboard" />
          </MenuItem>
          <MenuItem
            className={`${
              pathname.indexOf("jobs") !== -1 ? "side-nav__item--active" : ""
            }`}
            icon={<WorkIcon className="side-nav__icon" />}
          >
            Jobs
            <Link to="/jobs" />
          </MenuItem>
          <MenuItem
            className={`${
              pathname.indexOf("explore") !== -1 ? "side-nav__item--active" : ""
            }`}
            icon={<ExploreIcon className="side-nav__icon" />}
          >
            Explore students
            <Link to="/explore" />
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          <a
            href="https://github.com/azouaoui-med/react-pro-sidebar"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {}
            </span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
