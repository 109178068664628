import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import styled from "styled-components";
import logo from "../../assets/img/careerED white logo.png";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
export const Logo = styled("img")`
  height: ${({ size }) => size}rem;
`;
const HeadLink = styled(Link)`
  font-size: 1.4rem;
  color: #fff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export default function AppBarLandingPage(props) {
  const { bgcolor } = props;
  return (
    <Box sx={{ flexGrow: 1 }} style={bgcolor ? bgcolor : null}>
      <AppBar
        position="static"
        sx={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <Logo size={2} src={logo} />
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={4}>
            <HeadLink
              to="/about"
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              About
            </HeadLink>
            <HeadLink
              to="/contactus"
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              contact us
            </HeadLink>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
