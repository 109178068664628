export default function validationRules(values) {
  let errors = {};

  if (!values.title || values.title?.replace(/ /gi, "").length === 0) {
    errors.title = "Job title is required";
  }
  if (!values.lid) {
    errors.location = "Location is required";
  }
  if (!values.emptype) {
    errors.emptype = "Employment type is required";
  }
 
  if (!values.noopens || Number(values.noopens) < 1) {
    errors.noopens = "No.of openings is required";
  }
  if (!values.opfromdate) {
    errors.opfromdate = "Start date is required";
  }
  if (!values.optodate) {
    errors.optodate = "End date is required";
  }
  if (!values.reqexp) {
    errors.reqexp = "Please select Required experience";
  }
 
  if (!values.ctc || Number(values.ctc) < 1) {
    errors.ctc = "CTC is required";
  }
  if (!values.ctctype) {
    errors.ctctype = "CTC is required";
  }
  
  if (values.desc === undefined || values.desc?.replace(/ /gi, "").length === 0) {
    errors.desc = "Job description is required";
  }
  return errors;
}
