import React from "react";
import Amplify from "aws-amplify";
import awsExports from "./config/aws-exports";
import { store, persister } from "./redux/store";
import { Provider } from "react-redux";

import { render } from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import { HashRouter } from "react-router-dom";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

Amplify.configure(awsExports);

render(
  <Provider store={store}>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <PersistGate persistor={persister}>
          <App />
        </PersistGate>
      </QueryClientProvider>
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);
