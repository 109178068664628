import {
  FormControl,
  MenuItem,
  TextField,
  Select,
  Stack,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState, forwardRef, useEffect } from "react";
import NumberFormat from "react-number-format";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useForm } from "../../Hooks/useForm";
import { DatePicker } from "@mui/lab";
import { PostAJobButton } from "./Jobs";
// import useFetch from "../../Hooks/useFetch";
import styled from "styled-components";
import config from "../../config/aws-exports";
import moment from "moment";
import validationRules from "./ValidationRules";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import API from "@aws-amplify/api";

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="Rs."
    />
  );
});

export const CancelAJobButton = styled(Button)`
  background: ${({ theme }) => theme.color.white} !important;
  color: ${({ theme }) => theme.color.secondary} !important;
  border: 1px solid ${({ theme }) => theme.color.secondary} !important;
  text-transform: unset !important;
`;
export default function PostAndEditJobForm() {
  const userDetails = useSelector(authData);
  const location = useLocation();
  console.log("location==",location);
  const { state, pathname } = location;
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();
  const { data } = useQuery("masterData", () =>
    API.post(config.aws_cloud_logic_custom_name, "/masterData", {
      body: {
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    }).then((res) => {
      return res;
    })
  );

  const { values, handleChange, setValues } = useForm({});
  console.log(pathname);
  useEffect(() => {
    if (state !== null && state.edit) {
      setValues({
        title: state.title,
        desc: state.description,
        ctc: state.ctc,
        optodate: moment(state.op_end_date).format("YYYY-MM-DD"),
        opfromdate: moment(state.op_from_date).format("YYYY-MM-DD"),
        ctctype: state.ctc_type,
        emptype: state.emp_id,
        noopens: state.no_opens,
        lid: state.lid,
        reqexp: state.reqexp,
      });
    } else {
      setValues({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [errors, setErrors] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(Object.keys(validationRules(values)).length)
    if (Object.keys(validationRules(values)).length !== 0) {
      setErrors(validationRules(values));
      return;
    }  else {
      try {
        setLoading(true);
        const body = {
          ...values,
          noopens: parseInt(values.noopens),
          ctc: parseFloat(values.ctc),
          atype: state !== null && state.edit ? 2 : 1,
          jid: state !== null && state.edit ? state.jid : 0,
          cid: data?.body.mycompany.cid,
          ctctype: parseInt(values.ctctype),
          postby: userDetails.eid,
        };
        console.log(JSON.stringify(body, null, 2));
        await API.post(config.aws_cloud_logic_custom_name, "/jobUpdate", {
          body,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            // Authorization: jwttoken,
          },
        });
        pathname.indexOf("dashboard") !== -1
          ? navigate("/dashboard")
          : navigate("/jobs");
          setLoading(false);
        window.location.reload();
        setValues({});
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
      <Stack direction="column" spacing={2}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          spacing={{ xs: 0, md: 10 }}
        >
          <Stack>
            <Box>
              <Box htmlFor="company">Company</Box>
              <Box
                sx={{
                  p: 1,
                  border: `1.5px solid rgb(0 0 0 / 23%)`,
                  borderRadius: 0.8,
                  height: 40,
                }}
              >
                {data?.body.mycompany.cname}
              </Box>
            </Box>
            <Box >
              <Box htmlFor="jobtitle">Job title</Box>
              <TextField
                id="jobtitle"
                size="small"
                fullWidth
                value={values.title || ""}
                onChange={handleChange("title")}
                error={errors.title ? true : false}
                helperText={errors.title}
                required
              />
            </Box>
            <Box>
              <Box htmlFor="Location">Location</Box>
              <Box sx={{ minWidth: 300 }}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    size="small"
                    error={errors.location ? true : false}
                    value={values.lid || ""}
                    onChange={handleChange("lid")}
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {data?.body.location.map((loc) => (
                      <MenuItem key={loc.lid + "asdf"} value={loc.lid}>
                        {loc.lname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <Box htmlFor="Employment type">Employment type</Box>
              <Box sx={{ minWidth: 200 }}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    size="small"
                    error={errors.emptype ? true : false}
                    value={values.emptype || ""}
                    onChange={handleChange("emptype")}
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {data?.body.employment.map((emp) => (
                      <MenuItem key={emp.eid + "sdf"} value={emp.eid}>
                        {emp.emp_type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Stack>
          <Stack>
            <Box>
              <Box htmlFor="no.of openings">No.of openings</Box>
              <TextField
                id="no.of openings"
                size="small"
                type="number"
                fullWidth
                error={errors.noopens ? true : false}
                helperText={errors.noopens}
                value={values.noopens || ""}
                onChange={handleChange("noopens")}
                required
              />
            </Box>
            <Box>
              <Box htmlFor="Open from">Open from:</Box>
              <Box sx={{ mt: 1, display: "flex", gap: 1, flexWrap: "wrap" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disablePast
                    label="Start Date"
                    value={values.opfromdate || null}
                    onChange={(newValue) => {
                      const startDate = moment(newValue).format("YYYY-MM-DD");
                      setValues({ ...values, opfromdate: startDate });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={errors.opfromdate ? true : false}
                        color="info"
                        required
                        sx={{
                          mr: { xs: 0, sm: 1 },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="End Date"
                    value={values.optodate || null}
                    onChange={(newValue) => {
                      const endDate = moment(newValue).format("YYYY-MM-DD");
                      setValues({ ...values, optodate: endDate });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        color="info"
                        error={errors.optodate ? true : false}
                        required
                      />
                    )}
                    minDate={values.opfromdate}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box>
              <Box htmlFor="Required experience">Required experience</Box>
              <Box sx={{ maxWidth: 250 }}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    size="small"
                    error={errors.reqexp ? true : false}
                    value={values.reqexp || ""}
                    onChange={handleChange("reqexp")}
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {data?.body.reqexp.map((exp) => (
                      <MenuItem key={exp.req_id} value={exp.req_id}>
                        {exp.req_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <Box htmlFor="CTC">CTC</Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                <TextField
                  size="small"
                  error={errors.ctc ? true : false}
                  helperText={errors.ctc}
                  value={values.ctc || ""}
                  onChange={handleChange("ctc")}
                  name="CTC"
                  id="CTC"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  required
                />
                <Box sx={{ maxWidth: 250 }}>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      size="small"
                      value={values.ctctype || ""}
                      error={errors.ctctype ? true : false}
                      onChange={handleChange("ctctype")}
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {data?.body.ctctype.map((ctc) => (
                        <MenuItem key={ctc.ctc_id} value={ctc.ctc_id}>
                          {ctc.ctc_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Stack>
        <Stack>
          <Box htmlFor="Job description">Job description</Box>
          <TextField
            value={values.desc || ""}
            multiline
            rows={10}
            onChange={handleChange("desc")}
            required
            error={errors.desc ? true : false}
            helperText={errors.desc}
          />
        </Stack>
        <Stack direction="row" justifyContent="center" spacing={3}>
          {state !== null && state.edit && (
            <CancelAJobButton
              sx={{ width: "max-content" }}
              variant="contained"
              size="small"
              onClick={() => {
                // setValues({
                //   title: state.title,
                //   desc: state.description,
                //   ctc: state.ctc,
                //   optodate: moment(state.op_end_date).format("YYYY-MM-DD"),
                //   opfromdate: moment(state.op_from_date).format("YYYY-MM-DD"),
                //   ctctype: state.ctc_type,
                //   emptype: state.emp_id,
                //   noopens: state.no_opens,
                //   lid: state.lid,
                //   reqexp: state.reqexp,
                // });
               
                navigate(state.dashboard? `/dashboard/${state?.jid}` : `/jobs/${state?.jid}`,
                { state: { ...state, edit: false } })
              }}
            >
              Cancel 
            </CancelAJobButton>
          )}
          {!loading && <PostAJobButton
            sx={{ width: "max-content" }}
            variant="contained"
            size="small"
            type="submit"
          >
            { state !== null && state.edit ? "Save" : "Post job"}
          </PostAJobButton> }
          {loading && <PostAJobButton
            sx={{ width: "max-content" }}
            variant="contained"
            size="small"
            type="button"
          >
            Loading...
          </PostAJobButton> }
          
        </Stack>
      </Stack>

      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </form>
  );
}
