import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import "tippy.js/themes/light.css";
import "tippy.js/animations/perspective.css";
import React, { useState } from "react";

import styled from "styled-components";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate, Outlet, useLocation, Link } from "react-router-dom";

import { HeadingPrimary, HeadingSecondary } from "../../styles/Heading";
import { JobsCard, secondaryButtonStyles } from "../Dashboard/Dashboard";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
// import useFetch from "../../Hooks/useFetch";
import config from "../../config/aws-exports";
import API from "@aws-amplify/api";
import { useQuery } from "react-query";
import moment from "moment";

import DashboardAppBar from "../../components/AppHeader/AppBar.dashboardPage";
import { useEffect } from "react";

export const PostAJobButton = styled(Button)`
  background: ${({ theme }) => theme.color.primary} !important;
  color: ${({ theme }) => theme.color.white} !important;
  text-transform: unset !important;
  &:disabled {
    color: grey !important;
    background-color: lightgrey !important;
  }
`;
export const AppButton = styled(Button)`
  display: block !important;
  width: 135px;
  &:hover {
    text-decoration: underline !important;
  }
`;
const FILTER_MAP = {
  All: () => true,
  StatusOpen: (job) => job.status,
  StatusClose: (job) => !job.status,
  FullTime: (job) => job.emp_id === 1,
  PartTime: (job) => job.emp_id === 2,
};

export default function Jobs() {
  const userDetails = useSelector(authData);

  const { data } = useQuery("jobs", () =>
    API.post(config.aws_cloud_logic_custom_name, "/viewJob", {
      body: {
        atype: "cmpjob",
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err))
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [jobs, setJobs] = useState([]);

  const [searchField, setSearchField] = useState("");
  const [filter, setFilter] = useState("All");

  useEffect(() => {
    data?.body && setJobs(data.body.viewjob);
  }, [searchField, data]);
  console.log(data);

  const filteredJobs = jobs.filter((job) =>
    job.title.toLowerCase().includes(searchField.toLowerCase())
  );

  return (
    <main>
      <DashboardAppBar />
      {(pathname === "/jobs" || pathname === "/dashboard/viewAll") && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 3 }}
          >
            <HeadingPrimary>Posted jobs</HeadingPrimary>

            <Stack direction="row" alignItems="center" spacing={4}>
              <TextField
                id="search"
                size="small"
                color="info"
                placeholder="Search by job title..."
                type="search"
                onChange={(e) => setSearchField(e.target.value)}
              />
              <Stack direction="row" alignItems="center" spacing={1}>
                <p>Filter By:</p>
                <Box sx={{ minWidth: 200 }}>
                  <Tippy
                    content={
                      <>
                        <Box
                          sx={{
                            width: "100%",
                            minWidth: 200,
                          }}
                        >
                          <nav aria-label="main mailbox folders">
                            <List>
                              <ListItem disablePadding>
                                <ListItemButton dense>
                                  <ListItemText primary="Posted by me" />
                                </ListItemButton>
                              </ListItem>
                              <Tippy
                                content={
                                  <Box
                                    sx={{
                                      width: "100%",
                                      minWidth: 200,
                                      bgcolor: "background.paper",
                                    }}
                                  >
                                    <nav>
                                      <List>
                                        <ListItem disablePadding>
                                          <ListItemButton
                                            dense
                                            onClick={() => {
                                              setFilter("StatusOpen");
                                            }}
                                          >
                                            <ListItemText primary="Open" />
                                          </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                          <ListItemButton
                                            dense
                                            onClick={() => {
                                              setFilter("StatusClose");
                                            }}
                                          >
                                            <ListItemText primary="Close" />
                                          </ListItemButton>
                                        </ListItem>
                                      </List>
                                    </nav>
                                  </Box>
                                }
                                theme="light"
                                interactive={true}
                                inertia={true}
                                arrow={false}
                                duration={[350, 200]}
                                placement="left"
                              >
                                <ListItem disablePadding>
                                  <ListItemButton dense>
                                    <ListItemText primary="Status" />
                                  </ListItemButton>
                                </ListItem>
                              </Tippy>
                              <Tippy
                                content={
                                  <>
                                    <Box
                                      sx={{
                                        width: "100%",
                                        minWidth: 200,
                                        bgcolor: "background.paper",
                                      }}
                                    >
                                      <nav aria-label="main mailbox folders">
                                        <List>
                                          <ListItem disablePadding>
                                            <ListItemButton
                                              dense
                                              onClick={() => {
                                                setFilter("FullTime");
                                              }}
                                            >
                                              <ListItemText primary="Full time" />
                                            </ListItemButton>
                                          </ListItem>
                                          <ListItem disablePadding>
                                            <ListItemButton
                                              dense
                                              onClick={() => {
                                                setFilter("PartTime");
                                              }}
                                            >
                                              <ListItemText primary="Part Time" />
                                            </ListItemButton>
                                          </ListItem>
                                        </List>
                                      </nav>
                                    </Box>
                                  </>
                                }
                                theme="light"
                                interactive={true}
                                inertia={true}
                                arrow={false}
                                duration={[350, 200]}
                                placement="left"
                              >
                                <ListItem disablePadding>
                                  <ListItemButton dense>
                                    <ListItemText primary="Employment Type" />
                                  </ListItemButton>
                                </ListItem>
                              </Tippy>
                              <ListItem disablePadding>
                                <ListItemButton
                                  dense
                                  onClick={() => {
                                    setFilter("All");
                                  }}
                                >
                                  <ListItemText primary="All Jobs" />
                                </ListItemButton>
                              </ListItem>
                            </List>
                          </nav>
                        </Box>
                      </>
                    }
                    animation="perspective"
                    trigger="click"
                    theme="light"
                    interactive={true}
                    inertia={true}
                    arrow={false}
                    duration={[350, 200]}
                    placement="bottom"
                  >
                    <Button
                      sx={{
                        width: "100%",
                        justifyContent: "flex-start",
                        py: 1,
                      }}
                      variant="outlined"
                      size="small"
                      color="inherit"
                    >
                      {filter}
                    </Button>
                  </Tippy>
                </Box>
              </Stack>
              <PostAJobButton
                variant="contained"
                size="small"
                onClick={() => navigate("new")}
              >
                Post a job
              </PostAJobButton>
            </Stack>
          </Stack>

          <Grid container spacing={3} sx={{ mt: 3 }}>
            {filteredJobs.filter(FILTER_MAP[filter]).map((job, idx) => (
              <Grid key={idx + "ass"} item>
                <JobsCard>
                  <HeadingSecondary>{job.title}</HeadingSecondary>
                  <i>
                    {job.etype} .{job.lname}
                  </i>
                  <Stack direction="column" sx={{ mt: 1 }}>
                    <p>
                      Expires on:{" "}
                      {moment(job.op_end_date).format("DD MMM YYYY")}
                    </p>
                    <p>Post by: {job.postby}</p>
                    <Link
                      to={job.app_count === 0 ? "#" : "application"}
                      state={{ jid: job.jid, title: job.title }}
                    >
                      {job.app_count} Application
                    </Link>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <p>
                      Status :
                      <span className={job.status ? "open" : ""}>
                        {job.status ? "open" : "close"}
                      </span>
                    </p>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`${job.jid}`, { state: job })}
                      sx={secondaryButtonStyles}
                    >
                      View job
                    </Button>
                  </Stack>
                </JobsCard>
              </Grid>
            ))}
            {filteredJobs?.filter(FILTER_MAP[filter]).length === 0 && (
              <div style={{ padding: "28px" }}>No Jobs Found</div>
            )}
          </Grid>
        </>
      )}
      <Outlet />
    </main>
  );
}
