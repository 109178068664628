import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { HeadingPrimary } from "../../styles/Heading";
import { useLocation, useNavigate, Outlet } from "react-router";
import {
  Box,
  Chip,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Button,
  Stack,
} from "@mui/material";
import { Theme } from "../../config/aws-exports";
import { Add, Done } from "@mui/icons-material";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { API } from "aws-amplify";
import { useQuery } from "react-query";
import config from "../../config/aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import DashboardAppBar from "../../components/AppHeader/AppBar.dashboardPage";
import { PostAJobButton } from "../Jobs/Jobs";
// import EmailPopUp from "./EmailPopUp";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 55px);
  grid-row-gap: 1rem;
`;
const StyledGrid2 = styled.div`
  display: grid;
  grid-template-columns: minmax(60px, 150px) 300px;
  grid-column-gap: 1rem;
  align-items: center;
  p {
    text-align: right;
  }
`;
let grades = [
  { id: 4.0, title: "4.0 & above" },
  { id: 5.0, title: "5.0 & above" },
  { id: 6.0, title: "6.0 & above" },
  { id: 7.0, title: "7.0 & above" },
  { id: 8.0, title: "8.0 & above" },
  { id: 9.0, title: "9.0 & above" },
];
export default function Explore() {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useSelector(authData);
  const dispatch = useDispatch();

  // console.log("eid===", JSON.stringify(userDetails.eid));
  const { data } = useQuery("masterData", () =>
    API.post(config.aws_cloud_logic_custom_name, "/masterData", {
      body: {
        eid: userDetails.eid,
        stdmst: true,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    }).then((res) => {
      return res;
    })
  );
  // console.log("masterdata===", JSON.stringify(data));
  // const [chip1, setChip1] = useState(true);
  // const [chip2, setChip2] = useState(true);
  const [sdata, setSdata] = useState({});
  const [search, setSearch] = useState({});
  const [srcDisaplay, setSrcDisaplay] = useState();
  const [searcharray, setSearcharray] = useState([]);
  const [filterDisplay, setFilterDisplay] = useState({});
  const [filterBody, setFilterBody] = useState({});

  useEffect(() => {
    setFilterBody({});
  }, [location.pathname]);
  // const handleClick1 = () => {
  //   setChip1((prev) => !prev);
  //   if (chip1) setChip2(true);
  // };
  // const handleClick2 = () => {
  //   setChip2((prev) => !prev);
  //   if (chip2) setChip1(true);
  // };

  const handleChange = (event, label) => {
    const { name, value } = event.target;
    // console.log(value, "==sdsd==", name);
    let sdata = { ...userDetails };

    if (label === "College") {
      setSearcharray((currentQuestion) => [
        ...currentQuestion,
        {
          cid: 2,
          name: "test1",
        },
      ]);
      // var obj = {};
      // obj.cid = data?.body?.colleges[event.target.value].cid;
      // obj.name = data?.body?.colleges[event.target.value].cname;
      // setSearcharray((pre) => [...pre, obj]);
    }
    if (label === "Program") {
      setSearcharray((currentQuestion) => [
        ...currentQuestion,
        {
          pid: 1,
          name: "test",
        },
      ]);
      // var obj = {};
      // obj.pid = data?.body?.colleges[event.target.value].pid;
      // obj.name = data?.body?.colleges[event.target.value].pname;
      // setSearcharray((pre) => [...pre, obj]);
    }
    if (label === "Grades") {
      var obj = {};
      obj.id = data?.body?.colleges[event.target.value].id;
      obj.name = data?.body?.colleges[event.target.value].title;
      setSearcharray((pre) => [...pre, obj]);
    }
    // setSrcDisaplay(searcharray);
    setSearcharray((pre) => ({ ...pre, [name]: data.body[name][value] }));
    // console.log("sfsd", searcharray);
    sdata.srcDisplay = searcharray;
    sdata.prepage = "/explore";
    dispatch(awsSignIn(sdata));

    setSearch((pre) => ({ ...pre, [name]: data.body[name][value] }));
  };
  return (
    <main>
      <DashboardAppBar />
      {location.pathname === "/explore" && (
        <>
          <HeadingPrimary>Search</HeadingPrimary>
          <Box sx={{ boxShadow: Theme.boxShadow, p: 4, mt: 3, maxWidth: 1200 }}>
            <StyledGrid>
              <StyledGrid2>
                <p>College</p>
                <Box sx={{ minWidth: 300 }}>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      size="small"
                      defaultValue=""
                      onChange={(event) => {
                        let obj = {};
                        obj.name =
                          data?.body?.colleges[event.target.value].cname;
                        obj.cid = data?.body?.colleges[event.target.value].cid;
                        obj.ind = event.target.value;

                        setFilterDisplay((prev) => ({ ...prev, cid: obj }));
                        setFilterBody((prev) => ({
                          ...prev,
                          College: data?.body?.colleges[event.target.value].cid,
                        }));
                      }}
                      required
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {data?.body?.colleges.map((item, id) => (
                        <MenuItem key={id} value={id}>
                          {item.cname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </StyledGrid2>
              <StyledGrid2>
                <p>Program</p>
                <Box sx={{ minWidth: 300 }}>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      size="small"
                      defaultValue=""
                      onChange={(event) => {
                        // filterBody.Program =
                        //   data?.body?.programs[event.target.value].pid;
                        let obj = {};
                        obj.name =
                          data?.body?.programs[event.target.value].pname;
                        obj.pid = data?.body?.programs[event.target.value].pid;
                        obj.ind = event.target.value;
                        // filterDisplay.pid = obj;
                        setFilterDisplay((prev) => ({ ...prev, pid: obj }));
                        setFilterBody((prev) => ({
                          ...prev,
                          Program: data?.body?.programs[event.target.value].pid,
                        }));
                      }}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {data?.body?.programs.map((item, id) => (
                        <MenuItem key={id} value={id}>
                          {item.pname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </StyledGrid2>
              <StyledGrid2>
                <p>Grade</p>
                <Box sx={{ minWidth: 300 }}>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      size="small"
                      defaultValue=""
                      onChange={(event) => {
                        // filterBody.Grades = grades[event.target.value].id;
                        let obj = {};
                        obj.name = grades[event.target.value].title;
                        obj.gid = grades[event.target.value].id;
                        obj.ind = event.target.value;
                        // filterDisplay.gid = obj;
                        setFilterDisplay((prev) => ({ ...prev, gid: obj }));
                        setFilterBody((prev) => ({
                          ...prev,
                          Grades: grades[event.target.value].id,
                        }));
                      }}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {grades.map((item, id) => (
                        <MenuItem key={id} value={id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </StyledGrid2>
              {/* <CustomSelect
                label="College"
                name="colleges"
                items={
                  data?.body?.colleges === undefined ? [] : data?.body?.colleges
                }
                handleChange={handleChange}
              /> */}

              {/* <CustomSelect
                label="Grades"
                name="grades"
                items={grades}
                handleChange={handleChange}
              /> */}

              {/* <CustomSelect
                label="Program"
                name="programs"
                items={
                  data?.body?.programs === undefined ? [] : data?.body?.programs
                }
                handleChange={handleChange}
              /> */}
              <CustomSelect label="Graduation year" items={[]} />
              {/* <CustomSelect label="Course" items={data?.body?.courses === undefined ? [] : data?.body?.courses}
           handleChange={handleChange} /> */}
              <CustomSelect
                label="Experience in years"
                items={[]}
                handleChange={handleChange}
              />
              {/* <CustomSelect label="Location" items={data?.body?.location === undefined ? [] : data?.body?.location}
           handleChange={handleChange} /> */}
            </StyledGrid>
            {/* <Divider sx={{ mt: 4 }} /> */}
            {/* <Stack direction="row" spacing={1} sx={{ mt: 4 }}>
              <Chip
                icon={
                  chip1 ? <Add /> : <Done sx={{ fill: Theme.color.white }} />
                }
                label="Top 100 students"
                variant={!chip1 ? "contained" : "outlined"}
                sx={{
                  "&:focus": !chip1 && {
                    color: Theme.color.white,
                    background: Theme.color.secondary,
                  },
                  ":not(:focus)": !chip1 && {
                    color: Theme.color.white,
                    background: Theme.color.secondary,
                  },
                }}
                onClick={handleClick1}
              />
              <Chip
                icon={
                  chip2 ? <Add /> : <Done sx={{ fill: Theme.color.white }} />
                }
                label="Top 10 students"
                variant={!chip2 ? "contained" : "outlined"}
                sx={{
                  "&:focus": !chip2 && {
                    color: Theme.color.white,
                    background: Theme.color.secondary,
                  },
                  ":not(:focus)": !chip2 && {
                    color: Theme.color.white,
                    background: Theme.color.secondary,
                  },
                }}
                onClick={handleClick2}
              />
            </Stack> */}
          </Box>
          <Stack sx={{ mt: 4 }} direction="row" justifyContent="center">
            {/* <Button disabled={Object.keys(search).length == 0 ? true:false} primary onClick={() => navigate("search", { state: search })}>
              Search
            </Button> */}
            <PostAJobButton
              variant="contained"
              size="small"
              disabled={Object.keys(filterBody).length === 0 ? true : false}
              // onClick={() => {
              //   //  navigate("search", { state: search })
              // }}
              onClick={() => {
                if (Object.keys(filterBody).length > 0) {
                  let sdata = { ...userDetails };
                  filterBody.action = 0;
                  filterBody.eid = userDetails.eid;
                  sdata.searchBody = filterBody;
                  sdata.srcDisplay = filterDisplay;
                  sdata.prepage = "/explore";
                  dispatch(awsSignIn(sdata));
                  navigate("search");
                } else {
                  alert("At list one filter select");
                }
              }}
            >
              Search
            </PostAJobButton>
          </Stack>

          {/* <pre>{JSON.stringify(filterBody, null, 2)}</pre>
          <pre>{JSON.stringify(filterDisplay, null, 2)}</pre> */}
        </>
      )}

      <Outlet />
    </main>
  );
}
function CustomSelect({ label, name, items, handleChange }) {
  return (
    <StyledGrid2>
      <p>{label}</p>
      <Box sx={{ minWidth: 300 }}>
        <FormControl fullWidth>
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            size="small"
            name={name}
            defaultValue=""
            onChange={(event) => handleChange(event, label)}
          >
            <MenuItem value="">
              <em>Select</em>
            </MenuItem>
            {label === "Grades" &&
              items.map((item, id) => (
                <MenuItem key={id} value={id}>
                  {item.title}
                </MenuItem>
              ))}
            {label === "Location" &&
              items.map((item, index) => (
                <MenuItem key={index} value={item.lid}>
                  {item.lname}
                </MenuItem>
              ))}
            {label === "College" &&
              items.map((item, index) => (
                <MenuItem key={index} value={index}>
                  {item.cname}
                </MenuItem>
              ))}

            {label === "Program" &&
              items.map((item, index) => (
                <MenuItem key={index} value={index}>
                  {item.pname}
                </MenuItem>
              ))}
            {label === "Course" &&
              items.map((item, index) => (
                <MenuItem key={index} value={item.cid}>
                  {item.cname}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </StyledGrid2>
  );
}
