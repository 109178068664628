const size = {
  phone: "37.5em",
  tab_port: "56.25em",
  tab_land: "75em",
  big_desktop: "112.5em",
};

export const Theme = {
  color: {
    primary: "#f18121",
    secondary: "#0f80a4",
    secondaryrgb: "15, 128, 164",
    grey_light_1: "#f7f7f7",
    grey_light_2: "#eee",

    grey_dark: "#777",
    grey_dark_2: "#999",
    grey_dark_3: "#333",

    white: "#fff",
    black: "#000",
  },
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  breakpoint: {
    phone: `only screen and (max-width: ${size.phone})`,
    tab_port: `only screen and (max-width: ${size.tab_port})`,
    tab_land: `only screen and (max-width: ${size.tab_land})`,
    big_desktop: `only screen and (min-width: ${size.big_desktop})`,
  },
};

// const ALPK = "us-east-1:784bbcfe-d682-42e5-9ef5-344d01b6698c";
const careerportal = {
  DOMAIN: "https://d2xakjhdw97p05.cloudfront.net",
  aws_auth_facebook: "enable",
  main_color_1:"#f18121",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "buc28fw8j7",
      name: "HR-PLATFORM",
      description: "",
      endpoint:
        "https://buc28fw8j7.execute-api.us-east-1.amazonaws.com/prod283",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "buc28fw8j7",
      name: "HR-PLATFORM",
      description: "",
      endpoint:
        "https://buc28fw8j7.execute-api.us-east-1.amazonaws.com/prod283",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "HR-PLATFORM",
  aws_cloud_logic_custom_name_E: "HR-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:609e03e1-08c4-4c1f-8172-c48823a8dfd8",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-dev-learned",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain:
    "https://d2xakjhdw97p05.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  //aws_mobile_analytics_app_id: 'f085468f71664440ba6153ccb1036a9a',
  //aws_mobile_analytics_app_region: 'us-east-1',
  //aws_project_id: '5b55d3ef-e764-4657-b5c6-c0b023e8790e',
  //aws_project_name: 'EnhanzedRN',
  aws_project_region: "us-east-1",
  //aws_push_apns: 'enable',
  //aws_push_pinpoint: 'enable',
  aws_resource_name_prefix: "learned-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_R77pHRVMz",
  aws_user_pools_web_client_id: "1isp3ov441v2dfehsalc08bnsh",
  aws_cloud_logic_custom_endpoint:
    "https://buc28fw8j7.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "LEARNED",
  aws_org_id_E: "LEARNED",
  aws_user_pools_id_E: "us-east-1_R77pHRVMz",
  aws_user_pools_web_client_id_E: "1isp3ov441v2dfehsalc08bnsh",
  //aws_mobile_analytics_app_id_E: 'f085468f71664440ba6153ccb1036a9a',
  aws_content_delivery_cloudfront_domain_E:
    "https://d2xakjhdw97p05.cloudfront.net",
  aws_cognito_identity_pool_id_E:
    "us-east-1:609e03e1-08c4-4c1f-8172-c48823a8dfd8",
  aws_cloud_logic_custom_endpoint_E:
    "https://buc28fw8j7.execute-api.us-east-1.amazonaws.com/prod283",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "buc28fw8j7",
      name: "HR-PLATFORM",
      description: "",
      endpoint:
        "https://buc28fw8j7.execute-api.us-east-1.amazonaws.com/prod283",
      region: "us-east-1",
    },
  ],
  // oauth: {
  //   domain: 'enhanzedprod.auth.us-east-1.amazoncognito.com',
  //   scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  //   redirectSignIn: 'enhanzed://',
  //   redirectSignOut: 'enhanzed://',
  //   responseType: 'code',
  // },
};

export default careerportal;
