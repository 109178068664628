import { Box } from "@mui/system";
import styled from "styled-components";

import { Theme } from "../../config/aws-exports";

export const Header = styled.header`
  height: 100vh;
  background-image: linear-gradient(
      45deg,
      rgba(${Theme.color.secondaryrgb}, 0.8),
      rgba(${Theme.color.secondaryrgb}, 0.8)
    ),
    url("https://source.unsplash.com/user/erondu/1600x900");

  background-size: cover;
  background-position: top;
  position: relative;
`;

export const SignInContainer = styled(Box)`
  max-width: 750px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
`;
