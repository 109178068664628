import styled from "styled-components";

export const HeadingPrimary = styled.h1`
  font-family: inherit;
  font-weight: 800;
  /* font-size: 1.4vw; */
  font-size: 25px;
  cursor: text !important;
  /* margin: 0.2em; */
  /* @media screen and (max-width: 800px) {
    font-size: 18px;
  } */
`;
export const HeadingSecondary = styled.h2`
  font-family: inherit;
  font-weight: 800;
  font-size: 16px;

  text-transform: capitalize;
  /* margin-bottom: 8px; */

  &:hover {
    color: ${({ theme }) => theme.color.secondary};
  }
  @media screen and (max-width: 360px) {
    font-size: 14px;
  }
`;
export const HeadingSecondaryLight = styled.div`
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  transition: hover 1s ease-out;

  &:hover {
    color: ${({ theme }) => theme.color.secondary};
  }
`;
