import { Box } from "@mui/system";
import { Breadcrumbs, Button, Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";
import {
  Link,
  useLocation,
  useParams,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { Theme } from "../../../config/aws-exports";
import { HeadingPrimary, HeadingSecondary } from "../../../styles/Heading";
import moment from "moment";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
const textAreaStyles = {
  mt: 1,
  width: "100%",
  minHeight: "45vh",
  resize: "none",
  border: "none",
  outline: 0,

  "&:focus": {
    border: 0,
    outline: 0,
  },
};
const JobViewContainer = styled(Box)`
  a {
    cursor: pointer;
    color: ${({ theme }) => theme.color.secondary};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  p,
  i {
    /* color: ${({ theme }) => theme.color.grey_dark_2}; */
  }

  .open {
    color: green;
    font-weight: 600;
  }
`;
export default function JobView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { pathname, state } = location;

  const breadcrumbs = [
    <Link to={state?.dashboard ? "/dashboard" : "/jobs"}>
      {state?.dashboard ? "Dashboard" : "Posted job"}
    </Link>,
    <p>View job</p>,
  ];
  return (
    <JobViewContainer>
      {(pathname === `/jobs/${id}` || pathname === `/dashboard/${id}`) && (
        <>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ mb: 2 }}
          >
            {breadcrumbs}
          </Breadcrumbs>
          <HeadingPrimary>View job</HeadingPrimary>
          <Box
            sx={{
              p: 3,
              border: `1px solid ${Theme.color.grey_light_2}`,
              borderRadius: 1.5,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <HeadingSecondary>{state?.title}</HeadingSecondary>

                <i>
                  {state?.etype} &bull; {state?.lname}
                </i>
                <p style={{ display: "flex" }}>
                  {state?.no_opens} openings &bull;
                  {state?.app_count === 0 ? (
                    <>
                      <p style={{ color: "#0f80a4" }}>
                        {" "}
                        {state?.app_count} Application
                      </p>
                    </>
                  ) : (
                    <>
                      <Link to={"application"} state={{ jid: state?.jid }}>
                        {state?.app_count} Application
                      </Link>
                    </>
                  )}
                </p>
                <p>Required experience: {state?.req_exp}</p>
                <p>
                  CTC: {state?.ctc} {state?.ctype}
                </p>
                <p>
                  Status :{" "}
                  <span className={state?.status ? "open" : ""}>
                    {state?.status ? "open" : "close"}
                  </span>
                </p>
              </Box>
              <Box sx={{ alignSelf: "center" }}>
                <p>
                  Expires on :{" "}
                  {moment(state?.op_end_date).format("DD MMM YYYY")}
                </p>
                <p>Posted by : {state?.postby}</p>
              </Box>
            </Stack>
            <Box sx={{ mt: 2 }}>
              <HeadingSecondary>Job description &#58;</HeadingSecondary>
              <Box component="textarea" sx={textAreaStyles} readOnly>
                {state?.description}
              </Box>
            </Box>
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={5}
            sx={{ mt: 4 }}
          >
            <Button
              size="small"
              variant="outlined"
              color="info"
              sx={{ textTransform: "unset", px: 4 }}
              onClick={() => {
                state?.dashboard ? navigate("/dashboard") : navigate("/jobs");
              }}
            >
              Back
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="info"
              sx={{ textTransform: "unset", px: 4 }}
              onClick={() =>
                navigate("edit", { state: { ...state, edit: true } })
              }
            >
              Edit
            </Button>
          </Stack>
        </>
      )}
      <Outlet />
    </JobViewContainer>
  );
}
