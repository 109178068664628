import React, { useState, useEffect } from "react";
import AppBarLandingPage, {
    Logo,
  } from "../../components/AppHeader/AppBar.landingPage";

import { Link } from "react-router-dom";

import config from "../../config/aws-exports";
//import { Constants } from "../../../config/constants";
//import { API } from "aws-amplify";

function Abouts() {

  
 let bgclr = {backgroundColor:"#03b2cb"}
  return (
    <div>
       <AppBarLandingPage bgcolor={bgclr} />
      <div align="left" style={{ padding: "5% 20% 13% 20%" }}>
        <Link to={`/`}>
          <p
            style={{
              color: config.main_color_1,
              fontSize: "small",
              marginBottom: "30px",
              marginTop: "20px",
            }}
          >
            {" "}
            {`< Back To Home`}
          </p>
        </Link>

        <div style={{ fontSize: "16px" }}>
        <h2>Hire Directly from Pharmacy Institutions across the country</h2>
        
<ol style={{listStyle:'disc'}}>
    <li> Hire from the top pharmacy institutions</li>
    <li>Access verified profiles directly from institutions</li>
    <li>Post jobs and access applications from verified candidates.</li>
    <li> Get exclusive access to student personas</li>
    <li> Hiring made easy and trackable to any scale</li>
</ol>
   


        </div>
      </div>

    </div>
  );
}
export default Abouts;
