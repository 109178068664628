import { Routes, Route, Link } from "react-router-dom";

import { Theme } from "./config/aws-exports";
//Layouts
import DashboardLayout from "./layouts/DashboardLayout";
import JobLayout from "./layouts/JobLayout";
import ExploreLayout from "./layouts/ExploreLayout";
//Components
import Home from "./pages/Home/Home";
import NewJob from "./pages/Jobs/NewJob/NewJob";
import JobApplication from "./pages/Jobs/JobApplications/JobApplication";
import Profile from "./pages/Jobs/Profile/Profile";
import JobView from "./pages/Jobs/JobView/JobView";
import JobEdit from "./pages/Jobs/JobEdit/JobEdit";
import ContactUs from "./pages/ContactUs/ContactUs";
import Abouts from "./pages/About/About";
//styles
import { GlobalStyle } from "./index.styles";
import { ThemeProvider } from "styled-components";

import "./App.scss";
import Search from "./pages/Explore/Search";
function App() {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Abouts />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path=":id" element={<JobView />}>
            <Route path="edit" element={<JobEdit />} />
            <Route path="application/*" element={<JobApplication />}>
              <Route path="profile" element={<Profile />} />
            </Route>
          </Route>
          <Route path="application/*" element={<JobApplication />}>
            <Route path="profile" element={<Profile />} />
          </Route>
        </Route>
        <Route path="/dashboard/viewAll" element={<JobLayout />} />
        <Route path="/jobs" element={<JobLayout />}>
          <Route path=":id" element={<JobView />}>
            <Route path="edit" element={<JobEdit />} />
            <Route path="application/*" element={<JobApplication />}>
              <Route path="profile" element={<Profile />} />
            </Route>
          </Route>
          <Route path="application/*" element={<JobApplication />}>
            <Route path="profile" element={<Profile />} />
          </Route>
          <Route path="new" element={<NewJob />} />
        </Route>
        <Route path="/explore" element={<ExploreLayout />}>
          <Route path="search" element={<Search />}>
            <Route path="profile" element={<Profile />} />
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;

function About() {
  return (
    <>
      <main>
        <h2>Who are we?</h2>
        <p>That feels like an existential question, don't you think?</p>
      </main>
      <nav>
        <Link to="/">Home</Link>
      </nav>
    </>
  );
}
