import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";
import { useState } from "react";
import { Stack, TextField } from "@mui/material";

import { Theme } from "../../config/aws-exports";
import { LoadingButton } from "@mui/lab";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";

import Swal from "sweetalert2";

const btnStyle = {
  color: Theme.color.grey_dark,
  borderColor: Theme.color.grey_dark,
  textTransform: "unset",
  "&:hover": {
    color: Theme.color.grey_dark,
    borderColor: Theme.color.grey_dark,
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
};

const loadingStyle = {
  color: Theme.color.white,
  bgcolor: Theme.color.primary,
  borderColor: Theme.color.primary,
  textTransform: "unset",
  "&:hover": {
    color: Theme.color.white,
    bgcolor: Theme.color.primary,
    borderColor: Theme.color.primary,
  },
};

export default function EmailPopUp({ open, setOpen, usersList }) {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const handleClose = () => setOpen(false);

  const handleSend = () => {
    if (title === "") {
      setErrors({ ...errors, er1: "Title cannot be empty." });
      return;
    }
    if (message === "") {
      setErrors({ ...errors, er2: "Message cannot be empty." });
      return;
    }
    setLoading(true);
    API.post(config.aws_cloud_logic_custom_name, "/groupNotification", {
      body: {
        action: "EMAIL",
        title: title,
        message: message,
        userslist: usersList,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Successfully sent mail 😃",
          showConfirmButton: true,
          confirmButtonColor: Theme.color.primary,
        }).then((res) => {
          if (res.isConfirmed) {
            handleClose();
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const err = Object.keys(errors).length;

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box component="h2" sx={{ textAlign: "center", mb: 3 }}>
            Send Email
          </Box>
          <Stack direction="column" spacing={2}>
            <label htmlFor="title">Title/Subject</label>
            <TextField
              fullWidth
              size="small"
              onChange={(e) => setTitle(e.target.value)}
              error={err !== 0 ? true : false}
              helperText={errors.er1 || ""}
            />

            <label htmlFor="title">Description/Body</label>
            <TextField
              fullWidth
              multiline
              maxRows={5}
              onChange={(e) => setMessage(e.target.value)}
              error={err !== 0 ? true : false}
              helperText={errors.er2 || ""}
            />
          </Stack>

          <Stack direction="row" spacing={3} mt={3} justifyContent="center">
            <LoadingButton
              loading={loading ? true : false}
              variant="outlined"
              sx={loadingStyle}
              onClick={handleSend}
            >
              Send
            </LoadingButton>
            <Button
              variant="contained"
              size="small"
              color="inherit"
              onClick={handleClose}
              sx={btnStyle}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
