import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    initialJobs: (state, action) => {
      state.value = action.payload;
    },
    addAJob: (state, action) => {
      state.value = [...state.value, action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { initialJobs, addAJob } = jobsSlice.actions;

export default jobsSlice.reducer;
