import { Box } from "@mui/system";
import React from "react";
import { HeadingPrimary, HeadingSecondary } from "../../styles/Heading";
import styled from "styled-components";
import { Grid, Stack, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Theme } from "../../config/aws-exports";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
// import useFetch from "../../Hooks/useFetch";
import { authData } from "../../redux/auth/authSlice";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import moment from "moment";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import DashboardAppBar from "../../components/AppHeader/AppBar.dashboardPage";
export const Main = styled.main`
  a {
    color: ${Theme.color.secondary};
    text-decoration: none;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const TotalCard = styled(Box)`
  max-width: 250px;
  padding: 3.5% 6%;
  text-align: center;
  background-color: ${({ theme }) => theme.color.grey_light_2};
`;
export const JobsCard = styled(Box)`
  width: 320px;
  padding: 15px;
  text-align: left;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  h2 {
    margin-bottom: 1rem;
  }
  p,
  i {
    color: ${({ theme }) => theme.color.grey_dark_2};
  }
  a {
    cursor: pointer;
    color: ${({ theme }) => theme.color.secondary};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .open {
    color: green;
    font-weight: 600;
  }
`;
export const secondaryButtonStyles = {
  color: Theme.color.secondary,
  textTransform: "unset",
  borderColor: Theme.color.secondary,
};

export default function Dashboard() {
  const userDetails = useSelector(authData);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data, isLoading } = useQuery("jobsData", () =>
    API.post(config.aws_cloud_logic_custom_name, "/viewJob", {
      body: {
        atype: "recent",
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    }).then((res) => {
      return res;
    })
  );
  console.log(data);

  return (
    <Main>
      <DashboardAppBar />
      {pathname === "/dashboard" && (
        <>
          <HeadingPrimary>Total</HeadingPrimary>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={4}
            sx={{ mt: 3, mb: 6 }}
          >
            <TotalCard>
              <HeadingSecondary>
                {data?.body?.dashboard[0]["std_count"]}
              </HeadingSecondary>
              <p>Students</p>
            </TotalCard>
            <TotalCard>
              <HeadingSecondary>
                {data?.body?.dashboard[0]["col_count"]}
              </HeadingSecondary>
              <p>Colleges</p>
            </TotalCard>
            <TotalCard>
              <HeadingSecondary>
                {data?.body?.dashboard[0]["job_count"]}
              </HeadingSecondary>
              <p>Jobs posted</p>
            </TotalCard>
            <TotalCard>
              <HeadingSecondary>
                {data?.body?.dashboard[0]["app_count"]}
              </HeadingSecondary>
              <p>Application received</p>
            </TotalCard>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <HeadingPrimary>Recently posted jobs</HeadingPrimary>
            {/* <Link to="/dashboard/viewAll">View all</Link> */}
          </Stack>
          <Box
            sx={{
              mt: 3,
              p: 3,
              border: `1px solid ${Theme.color.grey_light_2}`,
              borderRadius: 1.5,
            }}
          >
            <Grid container spacing={3}>
              {isLoading && <p>Loading...</p>}
              {data?.body.viewjob.map((job, index) => (
                <Grid key={index + "hjk"} item>
                  <JobsCard>
                    <HeadingSecondary>{job.title}</HeadingSecondary>
                    <i>
                      {job.etype} .{job.lname}
                    </i>
                    <Stack direction="column" sx={{ mt: 1 }}>
                      <p>
                        Expires on:{" "}
                        {moment(job.op_end_date).format("DD MMM YYYY")}
                      </p>
                      <p>Post by: {job.postby}</p>
                      <Link
                        to={job.app_count === 0 ? "#" : "application"}
                        state={{ jid: job.jid, title: job.title }}
                      >
                        {job.app_count} Application
                      </Link>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <p>
                        Status :
                        <span className={job.status ? "open" : ""}>
                          {job.status ? "open" : "close"}
                        </span>
                      </p>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          navigate(`${job.jid}`, {
                            state: { ...job, dashboard: true },
                          })
                        }
                        sx={secondaryButtonStyles}
                      >
                        View job
                      </Button>
                    </Stack>
                  </JobsCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
      <Outlet />
    </Main>
  );
}
