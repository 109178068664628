import styled, { css } from "styled-components";

export const CustomButton = styled.button`
  background: transparent;
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.primary};
  margin: 0 1em;
  padding: 0.35em 1em;
  font-size: 1rem;
  width: max-content;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 500;
  ${({ primary, secondary, join }) =>
    (primary || secondary || join) &&
    css`
      &:focus {
        transform: scale(0.85);
      }
    `}

  ${(props) =>
    props.primary &&
    css`
      background: ${({ theme }) => theme.color.primary};
      color: #ffff;
      border: 2px solid "#ffff";
      font-size: 0.9rem;
    `}
  ${(props) =>
    props.secondary &&
    css`
      background: #ffff;
      color: ${({ theme }) => theme.color.primary};
      border: 1px solid #ffff;
    `}
  ${(props) =>
    props.join &&
    css`
      background: #6a5acd;
      border: 2px solid #6a5acd;
      color: #ffff;
      padding: 0.4em 1em;
    `}
  ${(props) =>
    props.loading &&
    css`
      &:disabled {
        background: transparent;
        border-radius: 3px;
        border: 2px solid ${({ theme }) => theme.color.primary};
        color: ${({ theme }) => theme.color.primary};
        margin: 0 1em;
        padding: 0.45em 2em;
      }
    `}
`;
