import { Box } from "@mui/system";
import AppBarLandingPage, {
  Logo,
} from "../../components/AppHeader/AppBar.landingPage";
import { Header, SignInContainer } from "./Home.styles";
import logo from "../../assets/img/careerED color logo.png";
import SignIn from "../../components/SignInAndSignUp/SignIn";

function Home() {
  return (
    <Box>
      <Header>
        <AppBarLandingPage />
        <SignInContainer sx={{ p: "4.5% 6.5%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: "3 3 2 3",
              mb: 10,
            }}
          >
            <Logo size={4} src={logo} />
          </Box>
          <SignIn />
        </SignInContainer>
      </Header>
    </Box>
  );
}
export default Home;
