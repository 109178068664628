import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";
import React, { useState } from "react";
import { HeadingPrimary, HeadingSecondary } from "../../../styles/Heading";
import { Theme } from "../../../config/aws-exports";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useLocation, Link, useNavigate, useMatch } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import { PostAJobButton } from "../Jobs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import config from "../../../config/aws-exports";
import { useQuery } from "react-query";
import { API } from "aws-amplify";
import ResumePopUp from "../../../components/ResumePopUp/ResumePopUp";
import Swal from "sweetalert2";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
export const InputContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 2;
  p {
    min-width: 60px;
    text-align: right;
    margin-right: 20px;
  }
`;
export const ProfileContainer = styled(Box)`
  th {
    font-weight: 700;
  }
`;

export default function Profile() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [academic, setAcademic] = useState([]);
  const [studentDetails, setStudentDetails] = useState({});
  const [rejectionText, setRejectionText] = useState("");
  const [rejectButtonClick, setRejectButtonClick] = useState(false);
  const match = useMatch("/");

  const { state } = location;
  const [open, setOpen] = React.useState(false);

  const { data, isLoading } = useQuery(["profile", state.jid], () =>
    API.post(config.aws_cloud_logic_custom_name, "/getWorkAndAcademi", {
      body: {
        jid: state?.jid,
        stdid: state?.std_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    }).then((res) => {
      console.log("idss.", state?.jid, state?.std_id);
      console.log("res profile==", res);
      setLoading(false);
      // setAcademic(res.body.academi);
      // setStudentDetails(res.body.stddtl);
      if (
        res?.body?.stddtl?.profile_img !== undefined &&
        res?.body?.stddtl?.profile_img !== null
      ) {
        var extype = res?.body?.stddtl?.profile_img.slice(
          (Math.max(0, res?.body?.stddtl?.profile_img.lastIndexOf(".")) ||
            Infinity) + 1
        );
        let primg1 = `${
          config.DOMAIN
        }/${config.aws_org_id.toLowerCase()}-resources/images/users/${
          res.body.stddtl.eid
        }/crprofile/profile.${extype}`;
        res.body.profileImg = primg1;
      }
      if (
        res?.body?.stddtl?.resume_img !== undefined &&
        res?.body?.stddtl?.resume_img !== null
      ) {
        let primg2 = `${
          config.DOMAIN
        }/${config.aws_org_id.toLowerCase()}-resources/images/users/${
          res.body.stddtl.eid
        }/crresume/resumefile.pdf`;
        res.body.resume = primg2;
      }
      const locs = res.body.stddtl.cloc.split(",");
      res.body.loc = locs;
      return res.body;
    })
  );
  const appOrRejrHandle = async (type, stdstatus) => {
    let appids = [];
    const bodyParam = {
      body: {
        atype: 2,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    appids.push(state?.app_id);
    bodyParam.body.appids = appids;
    bodyParam.body.astatus = stdstatus;
    if (stdstatus === 4) {
      bodyParam.body.rjstatus = rejectionText;
    } else {
      bodyParam.body.rjstatus = null;
    }

    console.log(JSON.stringify(bodyParam.body, 2));
    if (stdstatus === 4) {
      if (rejectionText !== "" && rejectionText.length !== 0) {
        try {
          console.log("appbody==", bodyParam.body);
          const response = await API.post(
            config.aws_cloud_logic_custom_name,
            "/approvalProfileOrApp",
            bodyParam
          );

          Swal.fire({
            title: "Success!",
            text: `Application rejected successfully`,
            icon: "success",
            dangerMode: false,
          }).then((result) => {
            if (result) {
              setRejectButtonClick(false);
              if (location.pathname.indexOf("/dashboard") !== -1) {
                navigate("/dashboard");
              } else {
                navigate("/jobs");
              }
              // window.location.reload();
            }
          });
        } catch (error) {
          console.error(error);
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: `Type in the reason for rejection`,
          icon: "error",
          dangerMode: false,
        }).then((result) => {
          if (result) {
          }
        });
      }
    } else if (stdstatus === 3) {
      try {
        console.log(JSON.stringify(bodyParam.body, null, 2));
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/approvalProfileOrApp",
          bodyParam
        );

        Swal.fire({
          title: "Success!",
          text: "Application approved successfully",
          icon: "success",
          dangerMode: false,
        }).then((result) => {
          if (result) {
            if (location.pathname.indexOf("/dashboard") !== -1) {
              navigate("/dashboard");
            } else {
              navigate("/jobs/application");
            }
            // window.location.reload();
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const breadcrumbs = [
    <Link to={state?.dashboard ? "/dashboard" : "/jobs"}>
      {state?.dashboard ? "Dashboard" : "Posted jobs"}
    </Link>,
    <Link
      to={state?.dashboard ? `/dashboard/application` : `/jobs/application`}
      state={state}
      // onClick={handleClick}
    >
      Application received - {state?.title}
    </Link>,
    <p>View profile</p>,
  ];
  console.log("profilestate===", state);
  return (
    <ProfileContainer>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <HeadingPrimary>{state?.std_name}</HeadingPrimary>

      <Stack
        direction="row"
        spacing={10}
        alignItems="center"
        sx={{ maxWidth: "1000px", mt: 3 }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Avatar
            sx={{
              height: 150,
              width: 150,
            }}
            src={data?.profileImg}
            alt="image"
          />
          <HeadingSecondary style={{ maxWidth: "400px", textAlign: "center" }}>
            {data?.stddtl?.head_line}
          </HeadingSecondary>
        </Box>

        <Stack direction="column" sx={{ flexGrow: 1 }} spacing={2}>
          <InputContainer>
            <p>Email</p>
            <TextField
              size="small"
              fullWidth
              value={data?.stddtl?.emailid}
              inputProps={{ readOnly: true }}
            />
          </InputContainer>
          <InputContainer>
            <p>Phone</p>
            <TextField
              size="small"
              fullWidth
              value={data?.stddtl?.ph_no || ""}
              inputProps={{ readOnly: true }}
            />
          </InputContainer>
          <InputContainer>
            <p>Location</p>
            <TextField
              size="small"
              fullWidth
              value={data?.loc}
              inputProps={{ readOnly: true }}
            />
          </InputContainer>
          <InputContainer>
            <p>Collage</p>

            <TextField
              size="small"
              fullWidth
              value={`${data?.stddtl?.cname || ""} , ${
                data?.stddtl?.cloc || ""
              }`}
              inputProps={{ readOnly: true }}
            />
          </InputContainer>
          <InputContainer>
            <p>Resume</p>
            <Button variant="outlined" color="info" onClick={handleClickOpen}>
              View/Download
            </Button>
          </InputContainer>
        </Stack>
      </Stack>

      <HeadingPrimary style={{ marginTop: "35px" }}>About</HeadingPrimary>
      <Box component="p" sx={{ my: 3, color: Theme.color.grey_dark }}>
        {data?.stddtl?.about_me}
      </Box>
      <Divider />
      <HeadingPrimary style={{ marginTop: "35px" }}>Skills</HeadingPrimary>
      <Stack
        direction="row"
        sx={{ color: Theme.color.grey_dark, my: 3 }}
        alignItems="center"
        flexWrap="wrap"
        spacing={2}
      >
        {data?.stddtl?.skills.map((skills, key) => {
          const skill = {
            1: "Communication",
            2: "Leadership",
            3: "Problem Solving",
            4: "Critical Thinking",
            5: "Creativity",
            6: "Teamwork",
          };
          return (
            <Button key={key} size="small" variant="outlined" color="inherit">
              {skills === "1" && skill[skills]}
              {skills === "2" && skill[skills]}
              {skills === "3" && skill[skills]}
              {skills === "4" && skill[skills]}
              {skills === "5" && skill[skills]}
              {skills === "6" && skill[skills]}
            </Button>
          );
        })}
      </Stack>
      <Divider />
      <HeadingPrimary style={{ marginTop: "35px" }}>
        Work experience
      </HeadingPrimary>
      {data?.workexp?.map((wkexp, key) => (
        <Box sx={{ my: 3 }}>
          <Stack direction="row" sx={{ color: Theme.color.grey_dark }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <HeadingSecondary style={{ color: Theme.color.black }}>
                {wkexp.title}
              </HeadingSecondary>{" "}
              &bull;
              <Box component="p">{wkexp.cname}</Box>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <HeadingSecondary style={{ color: Theme.color.black }}>
                {wkexp.yandm.replace(/,/gi, " ")}
              </HeadingSecondary>
              <Box component="p">
                ({wkexp.start_date} - {wkexp.end_date})
              </Box>
            </Box>
          </Stack>
          <Box component="p"> {wkexp.description}</Box>
        </Box>
      ))}
      <Divider />
      <HeadingPrimary style={{ marginTop: "35px" }}>Academics</HeadingPrimary>
      <Stack direction="row" sx={{ color: Theme.color.grey_dark, my: 3 }}>
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            color: Theme.color.grey_dark,
            border: `1px solid ${Theme.color.grey_dark}`,
          }}
        >
          <Table
            sx={{
              minWidth: 650,
              color: "inherit",
            }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead sx={{ color: Theme.color.grey_dark }}>
              <TableRow>
                <TableCell>Programs</TableCell>
                <TableCell align="left">Courses</TableCell>
                <TableCell align="left">Grades</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.academi?.map((row) => {
                const status = { status: "" };
                if (row.grade) {
                  if (parseFloat(row.grade) < 4.0) {
                    status.status = "Fail";
                  } else {
                    status.status = "Pass";
                  }
                } else {
                  status.status = "In progress";
                }
                return (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row">{row.pname}</TableCell>
                    <TableCell align="left">
                      <Stack direction="column">
                        {row.cname.map((course) => (
                          <span>{course}</span>
                        ))}
                      </Stack>
                    </TableCell>
                    <TableCell align="left">{row.grade}</TableCell>
                    <TableCell align="left">{status.status}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          justifyContent: "center",
          margin: "3rem 0",
        }}
      >
        {rejectButtonClick && (
          <textarea
            name="rejection"
            id="rejection-1"
            cols="60"
            rows="6"
            onChange={(e) => setRejectionText(e.target.value)}
            placeholder="Type in the reason for rejection"
            style={{ resize: "none", fontSize: "14px", padding: "1rem" }}
          ></textarea>
        )}
      </div>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        {rejectButtonClick && (
          <>
            <Button
              size="small"
              variant="outlined"
              sx={{
                color: Theme.color.primary,
                borderColor: Theme.color.primary,
                textTransform: "unset",
                "&:hover": {
                  color: Theme.color.primary,
                  borderColor: Theme.color.primary,
                  textTransform: "unset",
                },
              }}
              onClick={() => {
                setRejectButtonClick(false);
              }}
            >
              Cancel
            </Button>
            <PostAJobButton
              variant="contained"
              size="small"
              onClick={() => {
                appOrRejrHandle("profile", 4);
              }}
            >
              Reject
            </PostAJobButton>
          </>
        )}
        {state?.ap_status !== 3 && state?.ap_status !== 4 && (
          <>
            {!rejectButtonClick && (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    color: Theme.color.primary,
                    borderColor: Theme.color.primary,
                    textTransform: "unset",
                    "&:hover": {
                      color: Theme.color.primary,
                      borderColor: Theme.color.primary,
                      textTransform: "unset",
                    },
                  }}
                  onClick={() => {
                    setRejectButtonClick(true);
                  }}
                >
                  Reject
                </Button>
                <PostAJobButton
                  variant="contained"
                  size="small"
                  onClick={() => appOrRejrHandle("profile", 3)}
                >
                  Shortlist
                </PostAJobButton>
              </>
            )}
          </>
        )}

        {/* <Button
          size="small"
          variant="outlined"
          sx={{
            color: Theme.color.primary,
            borderColor: Theme.color.primary,
            textTransform: "unset",
            "&:hover": {
              color: Theme.color.primary,
              borderColor: Theme.color.primary,
              textTransform: "unset",
            },
          }}
        >
          Reject
        </Button>
        <PostAJobButton variant="contained" size="small">
          Shortlist
        </PostAJobButton> */}
      </Stack>
      {open && (
        <ResumePopUp
          open={open}
          setOpen={setOpen}
          src={data?.resume}
          name={state?.std_name}
        />
      )}
      <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress sx={{ color: Theme.color.secondary }} />
      </Backdrop>
    </ProfileContainer>
  );
}
